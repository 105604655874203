import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import Home from './components/home/Home';
import { Toaster } from 'react-hot-toast';
// import logo from './logo.svg'
import './App.css';
import PostPage from './components/post-page/PostPage';
import UserPage from './components/user/User';
import LoginPage from './components/login/Login';

function App() {
  return (
    <div>
      <div>
        <BrowserRouter>
          <Routes>
            <Route>
              <Route path="/home" element={<Home />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/post/:id" element={<PostPage />} />
              <Route path="/user/:id" element={<UserPage />} />
              <Route path="/" element={<Navigate replace to="/home" />} />
              <Route path="*" element={<Navigate replace to="/home" />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
      <Toaster position="top-right" />
    </div>
  );
}

export default App;
