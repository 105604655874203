import { getToken } from '../utils/helper';

// const BASE_URL = 'http://localhost:8080';
const BASE_URL = 'https://social-stag.condenastdigital.com';
const GraphQL_Url = `${BASE_URL}/api/graphql`;

const graphqlCall = async (data) => {
  try {
    const token = getToken();
    if (!token) {
      console.log(token, 'tokentokentoken');
      throw new Error('403 Forbidden: Missing Token');
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(GraphQL_Url, options);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const createPost = (variables) => {
  const data = {
    query:
      'mutation CreatePost($content: String!, $contentType: String, $media: MediaInput, $scheduledTime: String) {\n  createPost(content: $content, contentType: $contentType, media: $media, scheduledTime: $scheduledTime) {\n    message\n  }\n}',
    variables: variables,
  };
  return graphqlCall(data);
};
export const createComment = (variables) => {
  const data = {
    query:
      'mutation AddComment($input: CreateCommentInput!) {\n  addComment(input: $input) {\n    content\n  }\n}',
    variables: variables,
  };
  return graphqlCall(data);
};

export const addLike = (variables) => {
  const data = {
    query:
      'mutation AddLike($input: AddLikeInput!) {\n  addLike(input: $input) {\n    message\n  }\n}',
    variables: variables,
  };
  return graphqlCall(data);
};
export const getPosts = (variables) => {
  const data = {
    query:
      'query Posts{\n  posts {\n user {\n   _id\n   name\n  avtar\n    }\n   commentCount\n    _id\n    likeCount\n    content\n    contentType\n    createdAt\n    _id\n    likeCount\n    updatedAt\n      media {\n      mimetype\n      url\n    }\n  }\n}',
    variables: variables,
  };
  return graphqlCall(data);
};

export const getUserPosts = (variables) => {
  const data = {
    query:
      'query Posts($userId: ID){\n  posts(userId: $userId) {\n user {\n   _id\n   name\n  avtar\n    }\n   commentCount\n    _id\n    likeCount\n    content\n    contentType\n    createdAt\n    _id\n    likeCount\n    updatedAt\n      media {\n      mimetype\n      url\n    }\n  }\n}',
    variables: variables,
  };
  return graphqlCall(data);
};
export const getPost = (variables) => {
  const data = {
    query:
      'query Post($postId: ID!, $page: Int!, $pageSize: Int!) {\n  post(postId: $postId) {\n    content\n    likeCount\n    media {\n      url\n    }\n    commentCount\n    _id\n    comments(page: $page, pageSize: $pageSize) {\n  _id\n    content\n      createdAt\n      user {\n    _id\n    name\n  avtar\n    }\n    }\n    createdAt\n    user {\n    _id\n  name\n  avtar\n   }\n  }\n}',
    variables: variables,
  };
  return graphqlCall(data);
};

export const getUser = (variables) => {
  const data = {
    query:
      'query User($userId: ID) {\n  user(userId: $userId) {\n    amgUUID\n    _id\n    name\n  avtar\n   followerCount\n    followingCount\n  postCount\n }\n}',
    variables: variables,
  };
  return graphqlCall(data);
};

export const getFollowers = (variables) => {
  const data = {
    query:
      'query Followers($userId: ID!, $page: Int!, $pageSize: Int!) {\n  followers(userId: $userId, page: $page, pageSize: $pageSize) {\n    name\n    _id\n    followerCount\n  }\n}',
    variables: variables,
  };
  return graphqlCall(data);
};

export const getFollowing = (variables) => {
  const data = {
    query:
      'query Following($userId: ID!, $page: Int!, $pageSize: Int!) {\n  following(userId: $userId, page: $page, pageSize: $pageSize) {\n    name\n    _id\n    followerCount\n  }\n}',
    variables: variables,
  };
  return graphqlCall(data);
};

export const followUser = (variables) => {
  const data = {
    query:
      'mutation Follow($followedId: ID!) {\n  follow(followedId: $followedId) {\n    message\n  }\n}',
    variables: variables,
  };
  return graphqlCall(data);
};

export const unFollowUser = (variables) => {
  const data = {
    query:
      'mutation UnFollow($followedId: ID!) {\n  unFollow(followedId: $followedId) {\n    message\n  }\n}',
    variables: variables,
  };
  return graphqlCall(data);
};
