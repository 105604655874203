import { useNavigate } from 'react-router-dom';
import './Login.css';
import { useEffect, useState } from 'react';
import { getToken } from '../../utils/helper';
import { toast } from 'react-hot-toast';
import { openIdSignin } from '../../services/login.service';

const LoginPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = async () => {
    try {
      if (getToken()) {
        navigate('/home');
      }
    } catch (error) {}
  };

  const login = async () => {
    try {
      await toast.promise(openIdSignin(), {
        loading: 'Signing in...',
        success: <b> Success! </b>,
        error: <b>Could not process the request.</b>,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="center-container">
        <div className="d-flex center-content">
          <div className="logo-n">
            <img src="https://www.vogue.com/verso/static/vogue/assets/us/logo.svg" />
          </div>
          <div className="slash">/</div>
          <div className="comm">Community</div>
        </div>
      </div>
      <div className="conde-sign-btn">
        <button onClick={login}>Sign in with Condé Nast</button>
      </div>
    </div>
  );
};

export default LoginPage;
