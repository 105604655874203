import { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

import './AddPost.css';
import image from '../../assets/icons/image.svg';
import image_sel from '../../assets/icons/image_selected.svg';
import timer from '../../assets/icons/timer.png';
import timer_sel from '../../assets/icons/timer_sel.png';

import user from '../../assets/icons/user.jpeg';
import { formatDate } from '../../utils/helper';
import AuthorCard from '../author-card/AuthorCard';

const AddPost = ({ onAddPostSubmit, closeModal, selectedPost }) => {
  const [uploadUrl, setUploadUrl] = useState('');
  const [content, setContent] = useState('');
  const [selectUploadImg, setSelectUploadImg] = useState(false);
  const [selectSchedulePost, setSelectSchedulePost] = useState(false);
  const [scheduledTime, setScheduledTime] = useState(new Date());

  function uploadUrlChange(event) {
    setUploadUrl(event.target.value);
  }
  function contentChange(event) {
    setContent(event.target.value);
  }

  const handleSubmit = () => {
    const data = { content, uploadUrl };
    if (selectSchedulePost && scheduledTime) {
      data.scheduledTime = scheduledTime;
    }
    if (selectedPost) data.postId = selectedPost._id;
    onAddPostSubmit(data);
  };

  return (
    <div className="modal-content">
      <div className="d-flex flex-end">
        <div className="modal-heading-close" onClick={closeModal}>
          ✕
        </div>
      </div>
      <div className="modal-body">
        {selectedPost && (
          <div>
            <AuthorCard user={selectedPost.user} time={selectedPost.createdAt} />
            <div className="content">{selectedPost.content}</div>
            <div className="replyto">Replying to {selectedPost.user.name}</div>
          </div>
        )}
        <div className="d-flex">
          <textarea
            className="post-input"
            placeholder={selectedPost ? 'Write a reply' : 'Write a post'}
            value={content}
            onChange={contentChange}
          ></textarea>
        </div>
        {selectUploadImg && (
          <div>
            <div className="image-preview">
              <img src={uploadUrl} />
            </div>
            <div className="url-input">
              <div className="add-on d-flex">
                <div className="addon-label flex-a-c">Media</div>
                <div className="flex-1">
                  <input
                    type="text"
                    value={uploadUrl}
                    onChange={uploadUrlChange}
                    placeholder="Image url"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {selectSchedulePost && (
          <div className="add-on d-flex">
            <div className="addon-label flex-a-c">Date</div>
            <div className="flex-1">
              <DateTimePicker onChange={setScheduledTime} value={scheduledTime} />
            </div>
          </div>
        )}
        <div className="d-flex">
          <div className="d-flex flex-1">
            <div className="create-post-icon" onClick={() => setSelectUploadImg(!selectUploadImg)}>
              <img src={selectUploadImg ? image_sel : image} />
            </div>
            <div
              className="create-post-icon"
              onClick={() => setSelectSchedulePost(!selectSchedulePost)}
            >
              <img src={selectSchedulePost ? timer_sel : timer} />
            </div>
          </div>
          <div className="post-submit-btn">
            <button onClick={handleSubmit}>{selectedPost ? 'Reply' : 'Post'}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPost;
