import { useState } from 'react';
import Popup from 'reactjs-popup';
import './PostActions.css';
import AddPost from '../addpost/AddPost';
import comments from '../../assets/icons/comments.svg';
import like from '../../assets/icons/love.svg';
import { addLike, createComment } from '../../services/home.service';
import { toast } from 'react-hot-toast';

const PostActions = ({ post, openModal, loadPosts, loadUserPosts, loadPost, isComment }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  function openModal() {
    setOpenPopup(true);
  }
  function closeModal() {
    setOpenPopup(false);
  }

  async function submitLike(postId) {
    try {
      console.log(isComment, postId);
      const data = {
        input: {},
      };
      if (isComment) data.input.commentId = postId;
      if (!isComment) data.input.postId = postId;
      await toast.promise(addLike(data), {
        loading: 'Saving...',
        success: <b> Success! </b>,
        error: <b>Could not process the request.</b>,
      });
      if (loadPosts) loadPosts();
      if (loadUserPosts) loadUserPosts();
      if (loadPost) loadPost();
    } catch (error) {
      console.log(error);
    }
  }

  async function submitComment(postData) {
    try {
      const data = {
        input: {
          content: postData.content,
          media: { url: postData.uploadUrl },
        },
      };
      if (isComment) {
        data.input.parentId = post._id;
      }
      if (!isComment) {
        data.input.postId = post._id;
      }

      await toast.promise(createComment(data), {
        loading: 'Saving...',
        success: <b> Success! </b>,
        error: <b>Could not process the request.</b>,
      });
      closeModal();
      if (loadPosts) loadPosts();
      if (loadUserPosts) loadUserPosts();
      if (loadPost) loadPost();
    } catch (error) {
      // Handle error
      console.log(error);
    }
  }
  function openToComment(post) {
    setSelectedPost(post);
    openModal();
  }

  return (
    <div>
      <div className="post-actions">
        <div className="d-flex flex-j-e">
          <div className="action-item d-flex" onClick={() => openToComment(post)}>
            <div>
              <img src={comments} />
            </div>
            <div className="action-val">{post.commentCount || ''}</div>
          </div>
          <div className="action-item d-flex" onClick={() => submitLike(post._id)}>
            <div>
              <img src={like} />
            </div>
            <div className="action-val">{post.likeCount || ''}</div>
          </div>
        </div>
      </div>
      <div className="modal">
        <Popup open={openPopup} closeOnDocumentClick onClose={closeModal}>
          <AddPost
            onAddPostSubmit={submitComment}
            selectedPost={selectedPost}
            closeModal={closeModal}
          />
        </Popup>
      </div>
    </div>
  );
};

export default PostActions;
