import MainLayout from '../layout/MainLayout';
import './Home.css';

import { useEffect, useState } from 'react';
import { getPosts } from '../../services/home.service';

import Post from '../post/Post';
import { useNavigate } from 'react-router-dom';
import eventEmitter from '../../utils/eventEmitter.js';

export default function Home() {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadPosts();
    eventEmitter.on('homeposts', loadPosts);

    return () => {
      eventEmitter.off('homeposts', loadPosts);
    };
  }, []);

  const loadPosts = async () => {
    try {
      const result = await getPosts({}); // Adjust the endpoint
      setPosts(result.data?.posts);
    } catch (error) {
      catchError(error);
    }
  };

  // const posts = [

  //   {
  //     user: {
  //       name: 'Chathurbujan',
  //     },
  //     content: `This, it turns out, is only the beginning of the hotel’s artistic offerings: its interior is
  //   a harmonious blend of modern aesthetics and traditional Chinese elements, as portrayed in
  //   its use of colors, including rich cinnabar red undertones highlighted with shining gold
  //   sculptures. The combination serves as a reminder of China’s glorious history amid a
  //   technological utopia that closely resembles the cities depicted in Hollywood sci-fi
  //   blockbusters–a striking and almost surreal contrast.`,
  //     media: [
  //       {
  //         url: 'https://assets.vogue.com/photos/654a6deba85e70560bb1548c/master/w_1600,c_limit/Architecture.png',
  //       },
  //     ],
  //   },
  //   {
  //     user: {
  //       name: 'GEORGIA DAY',
  //     },
  //     content: `A simple way to ease yourself into the festive season, no reindeer knit required, there are myriad options when it comes to designs. To go all out and enjoy the spirit of the season, adorn your nails with tiny trails of tinsel or delicate snowflakes. For a base shade we like warm shades–think gingerbread and spice.`,
  //     media: [
  //       {
  //         url: 'https://assets.vogue.com/photos/65610dba657558e9558e425c/master/w_2240,c_limit/IMG_promo.jpg',
  //       },
  //     ],
  //   },
  //   {
  //     user: {
  //       name: 'Georgia Day',
  //     },

  //     media: [
  //       {
  //         url: 'https://assets.vogue.com/photos/65610dba657558e9558e425c/master/w_2240,c_limit/IMG_promo.jpg',
  //       },
  //     ],
  //   },
  //   {
  //     user: {
  //       name: 'Chiara Barzini',
  //     },

  //     media: [
  //       {
  //         url: 'https://assets.vogue.com/photos/65550081cdcc84004742fa33/master/w_2240,c_limit/1643-06.jpg',
  //       },
  //     ],
  //   },
  // ];
  const catchError = (error) => {
    if (error.message.includes('403')) {
      console.log('logout');
      navigate('/login');
    }
  };

  return (
    <MainLayout>
      <div>
        {posts &&
          posts.map((post, i) => (
            <div key={i}>
              <Post post={post} loadPosts={loadPosts} />
            </div>
          ))}
      </div>
    </MainLayout>
  );
}
