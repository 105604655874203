import { useNavigate, useParams } from 'react-router-dom';
import './PostPage.css';
import MainLayout from '../layout/MainLayout';
import Post from '../post/Post';
import { useEffect, useState } from 'react';
import { getPost } from '../../services/home.service';

const PostPage = () => {
  const [post, setPost] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    loadPost();
  }, []);

  const loadPost = async () => {
    try {
      const data = {
        postId: id,
        page: 1,
        pageSize: 10,
      };
      const result = await getPost(data); // Adjust the endpoint
      console.log(result, 'res');
      setPost(result.data?.post);
    } catch (error) {
      catchError(error);
    }
  };
  const catchError = (error) => {
    if (error.message.includes('403')) {
      console.log('logout');
      navigate('/login');
    }
  };

  return (
    <MainLayout>
      <div>{post && <Post post={post} loadPost={loadPost} />}</div>
    </MainLayout>
  );
};

export default PostPage;
