// const BASE_URL = 'http://localhost:8080';
const BASE_URL = 'https://social-stag.condenastdigital.com';
const GraphQL_Url = `${BASE_URL}/api/graphql`;

export const openIdSignin = async () => {
  try {
    const url = `${BASE_URL}/api/openid/signin`;
    console.log(url);
    const response = await fetch(`${BASE_URL}/api/openid/signin`, {
      method: 'get',
      redirect: 'follow',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseData = await response.json();
    console.log(responseData, 'responseDataresponseData');
    if (responseData.openidUrl) {
      window.location.href = responseData.openidUrl;
    }
    return responseData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
