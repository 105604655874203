import Cookies from 'js-cookie';

export const formatDate = (date) => {
  date = new Date(date);
  if (!date || isNaN(date.getTime())) {
    return '';
  }

  const options = {
    day: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const getToken = () => {
  return Cookies.get('token');
};
