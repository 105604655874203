import './AuthorCard.css';
import { formatDate } from '../../utils/helper';
import userAvtar from '../../assets/icons/user.jpeg';
import { useNavigate } from 'react-router-dom';

const AuthorCard = ({ user, time }) => {
  const navigate = useNavigate();

  function openUser(id) {
    if (!id) return;
    navigate(`/user/${id}`);
  }
  return (
    <div>
      {user && (
        <div className="middle-top">
          <div className={`d-flex ${time ? '' : 'flex-a-c'}`}>
            <div className="circle">
              <img src={user.avtar || userAvtar} />
            </div>
            <div>
              <div
                className={`author-name ${time ? '' : 'notime'}`}
                onClick={() => openUser(user._id)}
              >
                {user?.name || 'Unknown'}
              </div>
              <div className="post-time">{formatDate(time || '')} </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthorCard;
