import Popup from 'reactjs-popup';

import './Left-sidebar.css';
import home from '../../assets/icons/house.svg';
import search from '../../assets/icons/search.svg';
import bell from '../../assets/icons/notification.svg';
import tag from '../../assets/icons/tag.svg';
import bookmark from '../../assets/icons/bookmark.svg';

import { useState } from 'react';
import { createPost } from '../../services/home.service';
import AddPost from '../addpost/AddPost';
import { Link } from 'react-router-dom';
import eventEmitter from '../../utils/eventEmitter.js';

export default function LeftSidebar() {
  const [openPopup, setOpenPopup] = useState(false);

  function openModal() {
    setOpenPopup(true);
  }
  function closeModal() {
    setOpenPopup(false);
  }

  async function submitPost(postData) {
    try {
      const data = {
        content: postData.content,
        contentType: 'text',
        media: { url: postData.uploadUrl },
      };
      if (postData.scheduledTime) data.scheduledTime = postData.scheduledTime;
      const postDataResult = await createPost(data);
      console.log('Post data result:', postDataResult);
      eventEmitter.emit('homeposts', 'refresh');
      closeModal();
    } catch (error) {
      // Handle error
      console.log(error);
    }
  }

  return (
    <div>
      <div>
        <div className="logo">
          <img src="https://www.vogue.com/verso/static/vogue/assets/us/logo.svg" />
        </div>
        <div className="menu-list">
          <div className="menu-item">
            <div className="menu-item-icon">
              <img src={home} />
            </div>
            <Link to="/home">Home</Link>
          </div>
          <div className="menu-item">
            <div className="menu-item-icon">
              <img src={search} />
            </div>
            Explore
          </div>
          <div className="menu-item">
            <div className="menu-item-icon">
              <img src={bell} />
            </div>
            Notifications
          </div>
          <div className="menu-item">
            {' '}
            <div className="menu-item-icon">
              <img src={tag} />
            </div>
            Topics
          </div>
          <div className="menu-item">
            {' '}
            <div className="menu-item-icon">
              <img src={bookmark} />
            </div>
            Bookmarks
          </div>
        </div>
        <div>
          <div className="post-btn-cont">
            <button onClick={() => openModal()}>Post</button>
          </div>
        </div>
      </div>
      <div className="modal">
        <Popup open={openPopup} closeOnDocumentClick onClose={closeModal}>
          <AddPost onAddPostSubmit={submitPost} closeModal={closeModal} />
        </Popup>
      </div>
    </div>
  );
}
