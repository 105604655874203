import { useLocation } from 'react-router-dom';
import LeftSidebar from '../left-sidebar/Left-sidebar';
import RightSidebar from '../right-sidebar/Right-sidebar';
import './MainLayout.css';

const MainLayout = ({ children }) => {
  const location = useLocation();
  let currentPage = '';
  if (location.pathname.startsWith('/post')) {
    currentPage = 'post';
  } else if (location.pathname.startsWith('/user')) {
    currentPage = 'user';
  } else {
    currentPage = 'home';
  }
  return (
    <div className="app-cont">
      <div className="d-flex cont">
        <div className="l-sidebar">
          <div className="l-sidebar-inner">
            <LeftSidebar />
          </div>
        </div>
        <div className="middle-cont">
          {currentPage == 'home' && (
            <div className="middle-hdr">
              <div className="middle-hdr-menu-item active">Posts</div>
              <div className="middle-hdr-menu-item">Collections</div>
            </div>
          )}
          {currentPage == 'post' && <div className="page-hdr"> Post</div>}
          {/* {currentPage == 'user' && <div className="page-hdr"> User</div>} */}
          {children}
        </div>
        <div className="r-sidebar">
          <div className="r-sidebar-inner">
            <RightSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
