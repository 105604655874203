import './Post.css';
import { useNavigate } from 'react-router-dom';
import AuthorCard from '../author-card/AuthorCard';
import PostActions from '../post-actions/PostActions';

const Post = ({ post, loadPosts, loadUserPosts, loadPost }) => {
  const navigate = useNavigate();

  function openPost(id) {
    navigate(`/post/${id}`);
  }

  return (
    <div>
      {' '}
      <div className="post">
        <AuthorCard user={post.user} time={post.createdAt} />
        <div className="posts-body" onClick={() => openPost(post._id)}>
          <div>
            {post.content}

            {post.media?.length > 0 && (
              <div className="post-img">
                <img src={post.media[0].url} />
              </div>
            )}
          </div>
        </div>
        <PostActions
          post={post}
          loadPosts={loadPosts}
          loadUserPosts={loadUserPosts}
          loadPost={loadPost}
        />
        <div className="comments">
          {post.comments &&
            post.comments.map((comment, i) => (
              <div key={i} className="comment-item">
                <AuthorCard user={comment.user} time={comment.createdAt} />
                <div className="posts-body">
                  {comment.content}
                  {comment.media && comment.media.length && (
                    <div className="post-img">
                      <img src={comment.media[0].url} />
                    </div>
                  )}
                </div>
                <PostActions post={comment} isComment={true} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Post;
