import './Right-sidebar.css';
import search from '../../assets/icons/search.svg';
import tag from '../../assets/icons/tag.svg';

export default function RightSidebar() {
  return (
    <div>
      <div className="search">
        <img src={search} />
        <input type="text" />
      </div>
      <div className="trending card">
        <div className="card-title">Trending</div>
        <div className="card-body">
          <div className="trend-item">
            <div className="trend-item-title">#condenast</div>
            <div className="trend-item-count">10k Posts</div>
          </div>
          <div className="trend-item">
            <div className="trend-item-title">#vogue</div>
            <div className="trend-item-count">110k Posts</div>
          </div>
          <div className="trend-item">
            <div className="trend-item-title d-flex">
              <img src={tag} />
              vogueart
            </div>
            <div className="trend-item-count">3k Subscribers</div>
          </div>
        </div>
      </div>
      <div className="trending card">
        <div className="card-title">Topics</div>
        <div className="card-body">
          <div className="trend-item d-flex">
            <div className="flex-1">
              <div className="trend-item-title">
                <img src={tag} />
                Vogue Art
              </div>
              <div className="trend-item-count">3k Subscribers</div>
            </div>
            <div>
              <button>Subscribe</button>
            </div>
          </div>
          <div className="trend-item d-flex">
            <div className="flex-1">
              <div className="trend-item-title">
                <img src={tag} />
                Vogue Fashion
              </div>
              <div className="trend-item-count">10K Subscribers</div>
            </div>
            <div>
              <button>Subscribe</button>
            </div>
          </div>
          <div className="trend-item d-flex">
            <div className="flex-1">
              <div className="trend-item-title">
                <img src={tag} />
                Vogue Culture
              </div>
              <div className="trend-item-count">5k Subscribers</div>
            </div>
            <div>
              <button>Subscribe</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
