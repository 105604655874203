import { useNavigate, useParams } from 'react-router-dom';
import './User.css';
import MainLayout from '../layout/MainLayout';
import userAvtar from '../../assets/icons/user.jpeg';
import { useEffect, useState } from 'react';
import {
  getFollowers,
  getFollowing,
  getPosts,
  getUser,
  followUser,
  unFollowUser,
  getUserPosts,
} from '../../services/home.service';
import Post from '../post/Post';
import AuthorCard from '../author-card/AuthorCard';
import { toast } from 'react-hot-toast';

const UserPage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [posts, setPosts] = useState(null);
  const [followers, setFollowers] = useState(null);
  const [following, setFollowing] = useState(null);
  const [activePage, setActivePage] = useState('posts');

  const { id } = useParams();
  if (!id) {
    navigate('/home');
  }
  useEffect(() => {
    loadUser();
    loadUserPosts();
  }, []);

  const loadUser = async () => {
    try {
      const data = {
        userId: id,
        page: 1,
        pageSize: 10,
      };
      const result = await getUser(data); // Adjust the endpoint
      console.log(result, 'res');
      setUser(result.data?.user);
    } catch (error) {
      catchError(error);
    }
  };

  const loadUserPosts = async () => {
    try {
      const data = {
        userId: id,
        page: 1,
        pageSize: 10,
      };
      const result = await getUserPosts(data); // Adjust the endpoint
      setPosts(result.data?.posts);
    } catch (error) {
      catchError(error);
    }
  };

  const loadFollowers = async () => {
    try {
      const data = {
        userId: id,
        page: 1,
        pageSize: 10,
      };
      const result = await getFollowers(data); // Adjust the endpoint
      console.log(result, 'res');
      setFollowers(result.data?.followers);
    } catch (error) {
      catchError(error);
    }
  };
  const loadFollowing = async () => {
    try {
      const data = {
        userId: id,
        page: 1,
        pageSize: 10,
      };
      const result = await getFollowing(data); // Adjust the endpoint
      console.log(result, 'res');
      setFollowing(result.data?.following);
    } catch (error) {
      catchError(error);
    }
  };

  const onFollow = async (follow) => {
    try {
      const data = {
        followedId: follow._id,
      };
      await toast.promise(followUser(data), {
        loading: 'Following...',
        success: <b> Success! </b>,
        error: <b>Could not process the request.</b>,
      });

      // Adjust the endpoint
      loadUser();
      if (activePage === 'followers') {
        loadFollowers();
      }
      if (activePage === 'following') {
        loadFollowing();
      }
    } catch (error) {
      // Handle error
      console.log(error, 'err');
    }
  };

  const onUnFollow = async (follow) => {
    try {
      const data = {
        followedId: follow._id,
      };
      await toast.promise(unFollowUser(data), {
        loading: 'Following...',
        success: <b> Success! </b>,
        error: <b>Could not process the request.</b>,
      });

      loadUser();
      if (activePage === 'followers') {
        loadFollowers();
      }
      if (activePage === 'following') {
        loadFollowing();
      }
    } catch (error) {
      // Handle error
    }
  };

  const openSection = (section) => {
    setActivePage(section);
    if (section === 'followers') {
      loadFollowers();
    }
    if (section === 'following') {
      loadFollowing();
    }
  };

  const catchError = (error) => {
    if (error.message.includes('403')) {
      console.log('logout');
      navigate('/login');
    }
  };

  return (
    <MainLayout>
      {user && (
        <div className="user-cont">
          <div className="d-flex flex-a-c">
            <div className="user-circle">
              <img src={user.avtar || userAvtar} />
            </div>
            <div className="flex-1">
              <div className="user-name">{user.name}</div>
              <div className="user-posts">{user.postCount || '0'} Posts</div>
            </div>
            <div className="user-follow">
              <button onClick={() => onFollow(user)}>Follow</button>
            </div>
          </div>
          <div className="user-info-card">
            <div>I'm a Full stack developer, follow me to be the first who see my new work.</div>
            <div className="d-flex">
              <div className="stat-item">
                <div className="val">{user.followerCount || '0'}</div>
                <div className="key">Followers</div>
              </div>
              <div className="stat-item">
                <div className="val">{user.followingCount || '0'}</div>
                <div className="key">Following</div>
              </div>
            </div>
          </div>
          <div className="user-tabs d-flex flex-j-e">
            <div
              className={`tab-item ${activePage === 'posts' ? 'active' : ''}`}
              onClick={() => openSection('posts')}
            >
              Posts
            </div>
            <div
              className={`tab-item ${activePage === 'followers' ? 'active' : ''}`}
              onClick={() => openSection('followers')}
            >
              Followers
            </div>
            <div
              className={`tab-item ${activePage === 'following' ? 'active' : ''}`}
              onClick={() => openSection('following')}
            >
              Following
            </div>
            <div
              className={`tab-item ${activePage === 'collections' ? 'active' : ''}`}
              onClick={() => openSection('collections')}
            >
              Collections
            </div>
          </div>
          <div>
            {activePage === 'posts' && (
              <div>
                {posts &&
                  posts.map((post) => (
                    <div>
                      <Post post={post} loadUserPosts={loadUserPosts} />
                    </div>
                  ))}
              </div>
            )}
            {activePage === 'followers' && (
              <div>
                {followers &&
                  followers.map((follow) => (
                    <div className="d-flex flex-a-c">
                      <div className="d-flex flex-1">
                        <AuthorCard user={follow} />
                      </div>
                      <div className="user-follow-sec ">
                        <button onClick={() => onFollow(follow)}>Follow</button>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            {activePage === 'following' && (
              <div>
                {following &&
                  following.map((follow) => (
                    <div className="d-flex flex-a-c">
                      <div className="d-flex flex-1">
                        <AuthorCard user={follow} />
                      </div>
                      <div className="user-follow-sec">
                        <button onClick={() => onFollow(follow)}>Follow</button>
                        {/* <button onClick={() => onUnFollow(follow)}>Un Follow</button> */}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default UserPage;
